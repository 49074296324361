import { DbProxy } from "./Database";

export function getSongCount(db: DbProxy) {
    return db.read("SELECT COUNT(*) as cnt FROM streaminghistory")[0].cnt;
}

export function getDataRange(db: DbProxy, granularity: "year" | "month" | "day", filterList: string[] = [], filterParams: {[key: string]: string} = {}) {
    const timeFormat = getTimeFormat(granularity);
    const result = db.read(`SELECT strftime('${timeFormat}', MIN(timestamp)) as first, strftime('${timeFormat}', MAX(timestamp)) as last FROM streaminghistory WHERE TRUE AND ${filterList.join(" AND ")}`, filterParams)[0];

    let buffer;
    if (granularity == "day") {
        buffer = 24*60*60*1000/2;
    } else if (granularity == "month") {
        buffer = 30*24*60*60*1000/2;
    } else {
        buffer = 12*30*24*60*60*1000/2;
    }

    return [Date.parse(result.first as string) - buffer, Date.parse(result.last as string) + buffer]
}

export function getTimeFormat(granularity: "year" | "month" | "day") {
    switch (granularity) {
        case "year": return "%Y"
        case "month": return "%Y-%m"
        case "day": return "%Y-%m-%d"
    }
}