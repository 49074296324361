import { useContext, useEffect } from "react";
import { DbContext } from "../data/Database";
import { useNavigate } from "react-router-dom";
import { clear } from "../data/permanence";

export function Reset() {
    const db = useContext(DbContext);
    const navigate = useNavigate();

    useEffect(() => {
        db.write("DELETE FROM streaminghistory;");
        clear();
        navigate("/")
    });

    return <>Resetting...</>
}