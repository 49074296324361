import { Link, NavLink, Outlet } from "react-router-dom";
import { Welcome } from "./pages/Welcome";
import { Popover } from '@headlessui/react'
import { createContext, useContext, useState } from "react";
import { DbContext } from "./data/Database";
import { getSongCount } from "./data/datautil";
import "./Main.css";

export const GranularityContext = createContext<"year" | "month" | "day">("month")

export function Main() {
    const [loading, setLoading] = useState(false);
    const [granularity, setGranularity] = useState<"year" | "month" | "day">("month");
    const db = useContext(DbContext);

    if (loading || getSongCount(db) == 0) {
        return <Welcome loading={loading} setLoading={setLoading} />;
    }

    return <GranularityContext.Provider value={granularity}>
        <div className="main">
            <div className="header">
                <NavLink to="/reset">Reset</NavLink>
                <select onChange={(e) => setGranularity(e.target.value as "year" | "month" | "day")} value={granularity}>
                    <option value="year">Year</option>
                    <option value="month">Month</option>
                    <option value="day">Day</option>
                </select>
                <NavLink to="/help">Help</NavLink>
            </div>
            <div className="tabs">
                <NavLink to="/">Home</NavLink>
                <NavLink to="/songs">Songs</NavLink>
                <NavLink to="/artists">Artists</NavLink>
                <NavLink to="/monthly">Monthly</NavLink>
                <NavLink to="/sql">SQL</NavLink>
            </div>
            <div className="inner">
                <Outlet></Outlet>
            </div>
        </div>
    </GranularityContext.Provider>
}