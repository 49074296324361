import React, { useState, MutableRefObject, useRef, useEffect } from "react";
import initSqlJs, { BindParams, QueryExecResult, Database, Statement, SqlValue } from "sql.js";
import { load, store } from "./permanence";

export type DbProxy = {
    write(sql: string, params?: BindParams): void,
    read(sql: string, params?: BindParams): QueryResult,
    prepare(sql: string): StatementProxy,
    save(): void
}

type StatementProxy = {
    run: (params?: BindParams) => void,
    free: () => void
}

type DbContainer = { db: undefined | Database };

export type QueryResult = Record<string, SqlValue>[];

export const DbContext = React.createContext<DbProxy>({
    write() { throw Error("No DB") },
    read() { throw Error("No DB") },
    prepare() { throw Error("No DB") },
    save() { throw Error("No DB") },
})

export function DbProvider(props: any) {
    const bigData = [];
    const [updateTimestamp, setUpdateTimestamp] = useState(0);
    const [otherStuff, setOtherStuff] = useState(0);

    function tableUpdated() {
        setUpdateTimestamp(Date.now());
    }

    console.log("Rerendering Provider")

    const dbContainerRef: MutableRefObject<DbContainer> = useRef({ db: undefined })

    useEffect(() => {
        const getDatabase = async () => {
            console.warn("Getting DB")
            const SQL = await initSqlJs({
                // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
                // You can omit locateFile completely when running in node
                locateFile: file => {
                    console.log(`Locating file ${file}`);
                    return `/${file}`
                }
            });

            const existingDbData = await load();

            let db;
            if (existingDbData) {
                db = new SQL.Database(existingDbData);
            } else {
                db = new SQL.Database();

                db.run(`CREATE TABLE streaminghistory 
                (
                    timestamp TEXT,
                    username TEXT,
                    platform TEXT,
                    ms_played INTEGER,
                    conn_country TEXT,
                    ip_addr TEXT,
                    user_agent TEXT,
                    track_name TEXT,
                    artist_name TEXT,
                    album_name TEXT,
                    spotify_track_uri TEXT,
                    episode_name TEXT,
                    episode_show_name TEXT,
                    spotify_episode_uri TEXT,
                    reason_start TEXT,
                    reason_end TEXT,
                    shuffle INTEGER,
                    skipped INTEGER,
                    offline INTEGER,
                    offline_timestamp INTEGER,
                    incognito_mode INTEGER
                )
                STRICT`)
            }

            dbContainerRef.current.db = db;
            console.log("Added db to dbContainer")
            tableUpdated();
        }

        getDatabase()

        return () => {
            dbContainerRef.current.db?.close();
        }
    }, [])


    const cacheVersion = useRef(updateTimestamp)
    const cache = useRef(new Map<string, QueryResult>())

    if (updateTimestamp !== cacheVersion.current) {
        cacheVersion.current = updateTimestamp
        cache.current = new Map();
    }

    const dbProxy = {
        write(sql: string | Statement, params?: BindParams) {
            if (!dbContainerRef.current.db) {
                throw Error("No db yet, can't write");
            }
            console.log(`v${updateTimestamp}: Writing to DB`);

            if (typeof sql === "string") {
                dbContainerRef.current.db.run(sql, params);

            } else {
                sql.run(params)
            }
            tableUpdated();
        },

        read(sql: string, params?: BindParams) {
            const cacheKey = JSON.stringify({ sql, params })

            if (cache.current.has(cacheKey)) {
                console.log(`Using memoized value`)
                return cache.current.get(cacheKey)!;
            }

            if (!dbContainerRef.current.db) {
                throw Error("No db yet, can't read");
            }
            console.log(`v${updateTimestamp}: Reading from DB: ${sql}`);

            const queryResults = dbContainerRef.current.db.exec(sql, params);
            console.log("QueryResults:", queryResults)
            if (queryResults.length === 0) {
                return [];
            }

            const queryResult = queryResults[0];

            const objlist = Array.from(queryResult.values.map((row) => {
                const rowobj: Record<string, SqlValue> = {};
                row.forEach((v, i) => { rowobj[queryResult.columns[i]] = v })
                return rowobj;
            }))

            cache.current.set(cacheKey, objlist);
            return objlist;
        },

        prepare(sql: string): StatementProxy {
            if (!dbContainerRef.current.db) {
                throw Error("No db yet, can't prepare");
            }
            const stmt = dbContainerRef.current.db.prepare(sql)

            const run = (params?: BindParams) => {
                this.write(stmt, params);
            }

            return {
                run,
                free() {
                    stmt.free();
                    tableUpdated();
                }
            }
        },

        save(): void {
            store(dbContainerRef.current.db!.export())
        }
    };

    return (<DbContext.Provider value={dbProxy}>{dbContainerRef.current.db ? props.children : "Loading..."}</DbContext.Provider>);
}