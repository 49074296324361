import { useContext, useState } from "react";
import { DbContext, QueryResult } from "../data/Database";
import { AmountChart } from "../widgets/AmountChart";
import { getSongCount } from "../data/datautil";
import { ChartAndTable } from "../widgets/ChartAndTable";
import { ShowTable } from "../widgets/ShowTable";
import { useDebounce } from "use-debounce";

export function Songs() {
    const db = useContext(DbContext);
    const [currentSong, setCurrentSong] = useState<{ title?: string, artist?: string }>({});
    const [filters, setFilters] = useState<{ titlefilter: string, startDate: string, endDate: string }>({ titlefilter: "", startDate: "", endDate: "" });
    const [debouncedFilters] = useDebounce(filters, 200);

    if (getSongCount(db) === 0) {
        return <>No songs</>;
    }

    const filterList: string[] = [];
    const filterParams: { [key: string]: string } = {};

    filterList.push("episode_name IS NULL")

    if (debouncedFilters.titlefilter) {
        filterList.push(`track_name LIKE '%' || :titlefilter || '%'`)
        filterParams[":titlefilter"] = debouncedFilters.titlefilter;
    }

    if (debouncedFilters.startDate) {
        filterList.push(`timestamp >= :startdate`)
        filterParams[":startdate"] = debouncedFilters.startDate;
    }

    if (debouncedFilters.endDate) {
        filterList.push(`timestamp <= :enddate`)
        filterParams[":enddate"] = debouncedFilters.endDate;
    }

    const chartFilterList = structuredClone(filterList);
    const chartFilterParams = structuredClone(filterParams);

    if (currentSong.title) {
        chartFilterList.push(`track_name = :title`);
        chartFilterParams[":title"] = currentSong.title;
    }

    if (currentSong.artist) {
        chartFilterList.push(`artist_name = :artist`);
        chartFilterParams[":artist"] = currentSong.artist;
    }


    const top_songs_result = db.read(`
        SELECT
            RANK () OVER ( 
                ORDER BY sum(ms_played) DESC
            ) AS rank,
            track_name,
            artist_name,
            count(*) AS play_count,
            sum(ms_played)/(1000*60) AS minutes,
            spotify_track_uri as link
        FROM streaminghistory
        WHERE TRUE AND ${filterList.join(" AND ")}
        GROUP BY 
            track_name,
            artist_name
        
        ORDER BY count(*) DESC
        LIMIT 100;
    `, filterParams);

    const top_songs: QueryResult = structuredClone(top_songs_result);

    const maxPlayCount = top_songs.length > 0 ? top_songs.reduce(
        (prev, current) => {
            return (prev.play_count as number) > (current.play_count as number) ? prev : current
        }
    ) : undefined;

    top_songs.forEach((row) => {
        if ("link" in row && row.link != null) {
            const [_, path, id] = (row.link as string).split(":")
            console.log(_, path, id);
            row.link = `https://open.spotify.com/${path}/${id}`
        }
    })

    function titleFilterChange(e: any) {
        setFilters({ ...filters, titlefilter: e.target.value })
    }

    function startDateFilterChange(e: any) {
        setFilters({ ...filters, startDate: e.target.value })
    }

    function endDateFilterChange(e: any) {
        setFilters({ ...filters, endDate: e.target.value })
    }


    return <>{JSON.stringify(filters)}<ChartAndTable
        chart={<AmountChart filterList={chartFilterList} filterParams={chartFilterParams} ymax={maxPlayCount?.play_count as number}></AmountChart>}
        filter={
            <>
                <input type="text" onChange={titleFilterChange}></input>
                <input type="date" onChange={startDateFilterChange}></input>
                <input type="date" onChange={endDateFilterChange}></input>
            </>
        }
        table={<ShowTable rows={top_songs}
            onMouseEnter={(row) => setCurrentSong({ title: row.track_name as string, artist: row.artist_name as string })}
            onMouseLeave={(row) => setCurrentSong({})}
        />}
    /></>
}