import { Popover } from "@headlessui/react";
import { NavLink } from "react-router-dom";

export function Help() {
    return <>
        <NavLink to="/">Home</NavLink>
        <Popover>
            <Popover.Button>How do I use this?</Popover.Button>

            <Popover.Panel className="howtobox">
                <ul>
                    <li>Go to the <a href="https://www.spotify.com/us/account/privacy/">Spotify privacy page</a></li>
                    <li>Check "Select Extended streaming history"</li>
                    <li>Click "Request data"</li>
                    <li>Wait for first e-mail, confirm</li>
                    <li>Wait for second e-mail, download zip file</li>
                    <li>Load the zip file with the Button below</li>
                </ul>
            </Popover.Panel>
        </Popover>
        <Popover>
            <Popover.Button>How does this work?</Popover.Button>

            <Popover.Panel className="howtobox">
                <p>
                    Your data is analyzed locally in your Browser using <a href="https://sql.js.org/#/">SQL.js</a>, which is SQLite compiled for the Browser.
                </p>
                <p>
                    That means it is never uploaded to any server and securely stays on your computer. And it's <em>really fast</em>.
                </p>
            </Popover.Panel>
        </Popover>
        <Popover>
            <Popover.Button>Why does this website look like shit?</Popover.Button>

            <Popover.Panel className="howtobox">
                <p>
                    Hey! No need for that language. It's just not done yet.
                </p>
            </Popover.Panel>
        </Popover>
    </>
}