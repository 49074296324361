import { DbProxy } from "./data/Database";
import * as zip from "@zip.js/zip.js";
import { Progress, consolidateProgress } from "./util";

export async function* uploadFile(db: DbProxy, file: File): AsyncGenerator<Progress> {
    console.log("Chosen to upload:", file);
    if (file.type === "application/zip") {
        for await (const progress of uploadZipFile(db, file)) {
            yield progress;
        }
    } else if (file.type === "application/json") {
        const fileContent = await new Promise<string>((resolve, reject) => {
            var fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result as string)
            };
            fr.onerror = reject;
            fr.readAsText(file);
        });

        for await (const progress of saveExtendedJson(db, fileContent)) {
            yield progress;
        }
    }
}

export async function* uploadZipFile(db: DbProxy, file: File): AsyncGenerator<Progress> {
    const zipFileReader = new zip.BlobReader(file);
    const zipReader = new zip.ZipReader(zipFileReader);
    const entries = await zipReader.getEntries();
    entries.sort((a, b) => a.filename.localeCompare(b.filename));
    console.log(entries);
    
    const generators: AsyncGenerator<Progress>[] = (await Promise.all(entries.map(async (entry) => {
        if (entry.filename.includes("/Streaming_History_Audio")) {
            const contentWriter = new zip.TextWriter();
            const content = await entry.getData!(contentWriter);
            return saveExtendedJson(db, content);
        } else if (entry.filename.includes("/StreamingHistory")) {
            const contentWriter = new zip.TextWriter();
            const content = await entry.getData!(contentWriter);
            return saveUndetailedJson(db, content);
        }
        return null;
    }))).filter((x) => x != null) as AsyncGenerator<Progress>[];

    for await (const progress of consolidateProgress(generators)) {
        yield progress;
    }
}

export async function* saveExtendedJson(db: DbProxy, content: string): AsyncGenerator<Progress> {
    const songs = JSON.parse(content);

    console.log(`Reading ${songs.length} songs`)

    yield {progressed: 0, of: songs.length};

    const stmt = db.prepare(`INSERT INTO streaminghistory
        (
            timestamp,
            username,
            platform,
            ms_played,
            conn_country,
            ip_addr,
            user_agent,
            track_name,
            artist_name,
            album_name,
            spotify_track_uri,
            episode_name,
            episode_show_name,
            spotify_episode_uri,
            reason_start,
            reason_end,
            shuffle,
            skipped,
            offline,
            offline_timestamp,
            incognito_mode
        )
        VALUES
        (
            :timestamp,
            :username,
            :platform,
            :ms_played,
            :conn_country,
            :ip_addr,
            :user_agent,
            :track_name,
            :artist_name,
            :album_name,
            :spotify_track_uri,
            :episode_name,
            :episode_show_name,
            :spotify_episode_uri,
            :reason_start,
            :reason_end,
            :shuffle,
            :skipped,
            :offline,
            :offline_timestamp,
            :incognito_mode
        )`
        );

    for (const [i, e] of songs.entries()) {
        stmt.run({
            ":timestamp": e.ts,
            ":username": e.username,
            ":platform": e.platform,
            ":ms_played": e.ms_played,
            ":conn_country": e.conn_country,
            ":ip_addr": e.ip_addr_decrypted,
            ":user_agent": e.user_agent_decrypted,
            ":track_name": e.master_metadata_track_name,
            ":artist_name": e.master_metadata_album_artist_name,
            ":album_name": e.master_metadata_album_album_name,
            ":spotify_track_uri": e.spotify_track_uri,
            ":episode_name": e.episode_name,
            ":episode_show_name": e.episode_show_name,
            ":spotify_episode_uri": e.spotify_episode_uri,
            ":reason_start": e.reason_start,
            ":reason_end": e.reason_end,
            ":shuffle": e.shuffle,
            ":skipped": e.skipped,
            ":offline": e.offline,
            ":offline_timestamp": e.offline_timestamp,
            ":incognito_mode": e.incognito_mode
        });

        if (i % 300 == 0) {
            yield {progressed: i+1, of: songs.length}
        }
    };

    stmt.free();
    console.log("Done inserting.")
    return {progressed: songs.length, of: songs.length}
}

export async function* saveUndetailedJson(db: DbProxy, content: string): AsyncGenerator<Progress> {
    const songs = JSON.parse(content);

    console.log(`Reading ${songs.length} songs`)

    const stmt = db.prepare(`INSERT INTO streaminghistory
        (
            timestamp,
            username,
            platform,
            ms_played,
            conn_country,
            ip_addr,
            user_agent,
            track_name,
            artist_name,
            album_name,
            spotify_track_uri,
            episode_name,
            episode_show_name,
            spotify_episode_uri,
            reason_start,
            reason_end,
            shuffle,
            skipped,
            offline,
            offline_timestamp,
            incognito_mode
        )
        VALUES
        (
            :timestamp,
            :username,
            :platform,
            :ms_played,
            :conn_country,
            :ip_addr,
            :user_agent,
            :track_name,
            :artist_name,
            :album_name,
            :spotify_track_uri,
            :episode_name,
            :episode_show_name,
            :spotify_episode_uri,
            :reason_start,
            :reason_end,
            :shuffle,
            :skipped,
            :offline,
            :offline_timestamp,
            :incognito_mode
        )`
        );

        for (const [i, e] of songs.entries()) {
        stmt.run({
            ":timestamp": e.endTime,
            ":username": null,
            ":platform": null,
            ":ms_played": e.msPlayed,
            ":conn_country": null,
            ":ip_addr": null,
            ":user_agent": null,
            ":track_name": e.trackName,
            ":artist_name": e.artistName,
            ":album_name": null,
            ":spotify_track_uri": null,
            ":episode_name": null,
            ":episode_show_name": null,
            ":spotify_episode_uri": null,
            ":reason_start": null,
            ":reason_end": null,
            ":shuffle": null,
            ":skipped": null,
            ":offline": null,
            ":offline_timestamp": null,
            ":incognito_mode": null
        });

        if (i % 300 == 0) {
            yield {progressed: i+1, of: songs.length}
        }
    };

    stmt.free();
    console.log("Done inserting.")
    return {progressed: songs.length, of: songs.length}
}