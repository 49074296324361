import { useCallback, useContext, useEffect, useState } from "react";
import { DbContext, QueryResult } from "../data/Database";
import "./Sql.css";
import _ from "lodash";
import { ShowTable } from "../widgets/ShowTable";
import { useDebounce } from "use-debounce";

function getExampleSql(): string {
    const examples = [
`SELECT track_name, artist_name, count(*)
FROM streaminghistory
WHERE track_name LIKE '%love%'
GROUP BY track_name, artist_name
ORDER BY count(*) DESC
LIMIT 10`,

`SELECT artist_name, count(distinct track_name)
FROM streaminghistory
GROUP BY artist_name
ORDER BY count(distinct track_name) DESC`,

`SELECT track_name, count(*), sum(ms_played) / (1000 * 60) as minutes
FROM streaminghistory
WHERE artist_name = 'Queen'
GROUP BY track_name
ORDER BY count(*) DESC`
    ]

    return examples[Math.floor(Math.random() * examples.length)];
}

export function Sql() {
    const db = useContext(DbContext);
    const [result, setResult] = useState<{ rows?: QueryResult, error?: string, execution_ms?: number }>({});
    const [currentSql, setCurrentSql] = useState<string>(getExampleSql());
    const [debouncedSql] = useDebounce(currentSql, 200)
    console.log(`Set SQL: ${currentSql}`);

    const tables = db.read("PRAGMA table_list");

    const tableinfo = tables.map((table) => {
        const t = table as { schema: string, name: string, type: string, ncol: number, wr: number, string: number };
        if (t.name.startsWith("sqlite_")) {
            return null;
        }
        const info = db.read(`PRAGMA table_info(${table.name})`)
        return <p>{table.name}({info.map((info_row) => {
            return <p style={{ paddingLeft: "1em" }}>{info_row.name} {info_row.type}</p>
        })})</p>
    })

    useEffect(() => {
        const start = Date.now();
                try {
                    const result = db.read(debouncedSql);
                    setResult({ rows: result, execution_ms: Date.now() - start })
                } catch (e) {
                    if (e instanceof Error) {
                        setResult({ error: e.message, execution_ms: Date.now() - start })
                    }
                }
    }, [debouncedSql])

    let content;
    if (result.error) {
        content = <div className="error">{result.error}</div>;
    } else if (result.rows) {
        if (result.rows.length == 0) {
            content = <div className="no-results">No results</div>
        } else if (result.rows.length > 100) {
            content = <div><ShowTable rows={result.rows.slice(0, 100)} /><div className="not-shown">{result.rows.length - 100} more rows not shown</div></div>
        } else {
            content = <ShowTable rows={result.rows} />
        }
    } else {
        content = ""
    }

    return <div className="sql">
        <textarea className="prompt" value={currentSql} onChange={(event) => setCurrentSql(event.target.value)}></textarea>
        <div className="execution-time">
            Execution time: {result.execution_ms} ms
        </div>
        <div className="result">
            {content}
        </div>
        <div className="schema-header">
            Schema:
        </div>
        <div className="schema">
            {tableinfo}
        </div>
    </div>
}