import { useContext } from "react";
import { DbContext } from "../data/Database";
import "./Dashboard.css"

function getWidget(before: string, big: string, after: string) {
    return <div className="widget">
        <div className="before">{before}</div>
        <div className="big">{big}</div>
        <div className="after"><div className="after-inner">{after}</div></div>
    </div>
}

export function Dashboard() {
    const db = useContext(DbContext);
    const songCount = db.read("SELECT COUNT(*) as cnt FROM streaminghistory")[0].cnt as number;

    const shuffleCount = db.read("SELECT COUNT(*) as cnt FROM streaminghistory where shuffle = 1")[0].cnt as number;
    const shufflePercent = `${Math.round(shuffleCount / songCount * 100)} %`

    const listenDuration = db.read("SELECT SUM(ms_played) / (1000*60*60) as hrs FROM streaminghistory")[0].hrs as number;

    const distinctArtistCount = db.read("SELECT COUNT(distinct artist_name) as cnt FROM streaminghistory")[0].cnt;
    const distinctSongCount = db.read("SELECT COUNT(distinct track_name) as cnt FROM streaminghistory")[0].cnt;

    const longestDay = db.read(`
        SELECT strftime('%Y-%m-%d', timestamp) as date, sum(ms_played) / (1000*60*60) as hrs
        FROM streaminghistory 
        GROUP BY strftime('%Y-%m-%d', timestamp)
        ORDER BY sum(ms_played) DESC
        LIMIT 1
        `)[0];

    const firstSong = db.read(`
        SELECT min(strftime('%Y-%m-%d', timestamp)) as date, track_name
        FROM streaminghistory
        `)[0];

    const lastSong = db.read(`
        SELECT max(strftime('%Y-%m-%d', timestamp)) as date, track_name
        FROM streaminghistory
        `)[0];

    const widgets = [
        getWidget("There are", songCount!.toString(), "songs"),
        getWidget("First song", firstSong.date as string, ""),
        getWidget("Last song", lastSong.date as string, ""),
        getWidget("Listened a total of", listenDuration.toString() + " h", ""),
        shuffleCount > 0? getWidget("On shuffle", shufflePercent, "of the time") : null,
        getWidget("Listened to", distinctArtistCount!.toString(), "different artists"),
        getWidget("Listened to", distinctSongCount!.toString(), "different songs"),
        getWidget("Listened for", longestDay.hrs!.toString() + " h", `on ${longestDay.date}`)
    ];

    return <div className="dashboard">{widgets}</div>
}