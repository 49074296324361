import { useContext, useState } from "react";
import { DbContext } from "../data/Database";
import { AmountChart } from "../widgets/AmountChart";
import { getTimeFormat } from "../data/datautil";
import { GranularityContext } from "../Main";
import { ShowTable } from "../widgets/ShowTable";
import { ChartAndTable } from "../widgets/ChartAndTable";

export function Monthly() {
    const db = useContext(DbContext);
    const [currentSong, setCurrentSong] = useState<{ title?: string, artist?: string }>({});
    const granularity = useContext(GranularityContext);

    if (db.read("SELECT COUNT(*) as cnt FROM streaminghistory")[0].cnt === 0) {
        return <>No songs</>;
    }

    const chartFilterList: string[] = ["episode_name IS NULL"];
    const chartFilterParams: {[key: string]: string} = {}

    if (currentSong.title) {
        chartFilterList.push(`track_name = :title`);
        chartFilterParams[":title"] = currentSong.title;
    }

    if (currentSong.artist) {
        chartFilterList.push(`artist_name = :artist`);
        chartFilterParams[":artist"] = currentSong.artist;
    }

    const timeFormat = getTimeFormat(granularity);

    const monthly_top_songs = db.read(`
        SELECT
            month,
            track_name,
            artist_name,
            MAX(count) AS play_count,
            ms_played/(1000*60) AS minutes
        FROM (
            SELECT
                STRFTIME('${timeFormat}', timestamp) AS month,
                track_name,
                artist_name,
                count(*) AS count,
                sum(ms_played) AS ms_played
            FROM streaminghistory
            WHERE episode_name IS NULL
            GROUP BY 
                STRFTIME('${timeFormat}', timestamp),
                track_name,
                artist_name
            )
        GROUP BY month
        ORDER BY month;
    `);

    const maxPlayCount = monthly_top_songs.reduce(
        (prev, current) => {
            return (prev.play_count as number) > (current.play_count as number) ? prev : current
        }
    );


    return <ChartAndTable
        chart={<AmountChart filterList={chartFilterList} filterParams={chartFilterParams} ymax={maxPlayCount.play_count as number}></AmountChart>}
        table={<ShowTable rows={monthly_top_songs}
            onMouseEnter={(row) => setCurrentSong({ title: row.track_name as string, artist: row.artist_name as string })}
            onMouseLeave={(row) => setCurrentSong({})}
        />} />
}