import { useContext } from 'react';
import Plot from 'react-plotly.js';
import { DbContext } from '../data/Database';
import { getDataRange, getTimeFormat } from '../data/datautil';
import { max } from 'lodash';
import { GranularityContext } from '../Main';
import "./AmountChart.css"

export function AmountChart(props: { filterList?: string[], filterParams?: { [key: string]: string }, ymax?: number }) {
    const db = useContext(DbContext);
    const granularity = useContext(GranularityContext);

    const timeFormat = getTimeFormat(granularity)

    if (!props.filterList) {
        props.filterList = [];
    }

    if (!props.filterParams) {
        props.filterParams = {};
    }

    if (props.filterList?.length == 0) {
        props.filterList.push("TRUE");
    }

    const result = db.read(`
        SELECT
            strftime('${timeFormat}', timestamp) as time,
            count(*) as play_count
        FROM streaminghistory
        WHERE ${props.filterList?.join(" AND ")}
        GROUP BY strftime('${timeFormat}', timestamp)
        ORDER BY strftime('${timeFormat}', timestamp)`, props.filterParams)

    const dataRange = getDataRange(db, granularity, props.filterList, props.filterParams)

    const listenedMonths: Record<string, number> = {}
    result.forEach(element => {
        listenedMonths[element.month as string] = element.play_count as number
    });

    const x = result.map((r) => (r.time as string) + (granularity === "year" ? "-01" : ""))
    const y = result.map((r) => r.play_count as number)

    console.log("Result:", x, y)

    const actualYmax = max(y)!;

    const ymax = props.ymax && props.ymax > actualYmax ? props.ymax : actualYmax;

    let barwidth;
    if (granularity === "day") {
        barwidth = 24 * 60 * 60 * 1000
    }
    else if (granularity === "month") {
        barwidth = 30 * 24 * 60 * 60 * 1000 * 0.8
    } else if (granularity === "year") {
        barwidth = 12 * 30 * 24 * 60 * 60 * 1000 * 0.8
    }

    return <Plot className='amountchart'
        data={[
            {
                x,
                y,
                type: 'bar',
                mode: 'lines',
                marker: { color: 'red' },
                width: barwidth
            },

        ]}
        layout={{
            xaxis: { range: dataRange },
            yaxis: { range: [0, ymax] },
            autosize: true,
            margin: { l: 50, t: 20, r: 20, b: 50 }
        }}
        useResizeHandler />;
}