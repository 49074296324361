import React, { ChangeEvent, MutableRefObject, ReactNode, StrictMode, memo, useContext, useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import initSqlJs, { BindParams, Database, QueryExecResult } from 'sql.js'
import { DbContext, DbProvider } from './data/Database';
import { Monthly } from './pages/Monthly';
import {
  createBrowserRouter,
  Link,
  RouterProvider,
} from "react-router-dom";
import { Main } from './Main';
import { Songs } from './pages/Songs';
import { Sql } from './pages/Sql';
import { Artists } from './pages/Artists';
import { Dashboard } from './pages/Dashboard';
import { Help } from './pages/Help';
import { Reset } from './pages/Reset';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Dashboard />
      },
      {
        path: "/songs",
        element: <Songs />
      },
      {
        path: "/artists",
        element: <Artists />
      },
      {
        path: "/monthly",
        element: <Monthly />
      },
      {
        path: "/sql",
        element: <Sql />
      }
    ]
  },
  {
    path: "/reset",
    element: <Reset />
  },
  {
    path: "/help",
    element: <Help />
  }
])

function App() {
  return (
    <div className="App">
      <DbProvider>
        <RouterProvider router={router} />
      </DbProvider>
    </div>
  );
}

export default App;
