import "./ChartAndTable.css"

export function ChartAndTable(props: { chart: JSX.Element, filter?: JSX.Element, table: JSX.Element }) {
    return <div className="chart-and-table">
        <div className="chart">
            {props.chart}
        </div>
        {props.filter && <div className="filter">{props.filter}</div>}
        <div className="table">
            {props.table}
        </div>
    </div>
}