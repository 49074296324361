import { useContext, useState } from "react";
import { DbContext } from "../data/Database";
import { AmountChart } from "../widgets/AmountChart";
import { getSongCount } from "../data/datautil";
import { ShowTable } from "../widgets/ShowTable";
import { ChartAndTable } from "../widgets/ChartAndTable";

export function Artists() {
    const db = useContext(DbContext);
    const [currentArtist, setCurrentArtist] = useState<string | undefined>(undefined);

    if (getSongCount(db) === 0) {
        return <>No songs</>;
    }

    const chartFilterList: string[] = ["episode_name IS NULL"];
    const chartFilterParams: {[key: string]: string} = {}

    if (currentArtist) {
        chartFilterList.push(`artist_name = :artist`)
        chartFilterParams[":artist"] = currentArtist;
    }

    const top_songs = db.read(`
        SELECT
            RANK () OVER ( 
                ORDER BY sum(ms_played) DESC
            ) AS rank,
            artist_name,
            count(*) AS play_count,
            sum(ms_played)/(1000*60) AS minutes
        FROM streaminghistory
        WHERE episode_name IS NULL
        GROUP BY 
            artist_name
        
        ORDER BY count(*) DESC
        LIMIT 100;
    `);

    const maxPlayCount = top_songs.reduce(
        (prev, current) => {
            return (prev.play_count as number) > (current.play_count as number) ? prev : current
        }
    );


    return <ChartAndTable
        chart={<AmountChart filterList={chartFilterList} filterParams={chartFilterParams} ymax={maxPlayCount.play_count as number}></AmountChart>}
        table={<ShowTable rows={top_songs}
            onMouseEnter={(row) => setCurrentArtist(row.artist_name as string)}
            onMouseLeave={(row) => setCurrentArtist(undefined)}/>}
    />
}