export type Progress = {progressed: number, of: number}

function sumProgresses(progresses: Progress[]) {
    const s: Progress = {progressed: 0, of: 0}

    for (const p of progresses) {
        s.progressed += p.progressed;
        s.of += p.of;
    }

    return s;
}

export async function* consolidateProgress(progressors: AsyncGenerator<Progress>[]): AsyncGenerator<Progress> {
    const progresses: Progress[] = []
    for (const progressor of progressors) {
        const firstresult = await progressor.next();
        if (firstresult.done) {
            progresses.push({progressed: 0, of: 0});
        } else {
            progresses.push(firstresult.value);
        }
    }

    yield sumProgresses(progresses);

    for (const [i, progressor] of progressors.entries()) {
        for await (const progress of progressor) {
            progresses[i] = progress;
            yield sumProgresses(progresses);
        }
    }
}