function onUpgradeNeeded(event: any) {
    const db: IDBDatabase = event.target.result;
    const objectStore = db.createObjectStore("storage", { keyPath: "id" })
}

export function store(dbexport: Uint8Array): void {
    if (!('indexedDB' in window)) {
        console.log("This browser doesn't support IndexedDB");
        return;
    }

    const request = indexedDB.open("GDPRnalysis", 1);
    request.onupgradeneeded = onUpgradeNeeded;
    request.onsuccess = function (event: any) {
        const db: IDBDatabase = event.target.result;

        const transaction = db.transaction(["storage"], "readwrite")
        const objectStore = transaction.objectStore("storage")
        objectStore.put({ "id": "database", "value": dbexport })

        transaction.oncomplete = function () {
            db.close()
            console.log("Done saving to IndexedDB")
        }
    }
}

export function load(): Promise<Uint8Array | undefined> {
    if (!('indexedDB' in window)) {
        console.log("This browser doesn't support IndexedDB");
        return Promise.resolve(undefined);
    }

    return new Promise((resolve) => {
        const request = indexedDB.open("GDPRnalysis", 1);
        request.onupgradeneeded = onUpgradeNeeded;
        request.onsuccess = function (event: any) {
            const db: IDBDatabase = event.target.result;

            const transaction = db.transaction(["storage"], "readonly")
            const objectStore = transaction.objectStore("storage")
            var getDatabase = objectStore.get("database")

            getDatabase.onsuccess = function(x) {
                resolve(getDatabase.result?.value)
            }

            transaction.oncomplete = function () {
                db.close()
                console.log("Done loading from IndexedDB")
            }
        }
    })
}

export function clear(): void {
    if (!('indexedDB' in window)) {
        console.log("This browser doesn't support IndexedDB");
    }

    const request = indexedDB.open("GDPRnalysis", 1);
    request.onupgradeneeded = onUpgradeNeeded;
    request.onsuccess = function (event: any) {
        const db: IDBDatabase = event.target.result;

        const transaction = db.transaction(["storage"], "readwrite")
        const objectStore = transaction.objectStore("storage")
        objectStore.delete("database")

        transaction.oncomplete = function () {
            db.close()
            console.log("Done clearing from IndexedDB")
        }
    }
}