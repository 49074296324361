import { SqlValue } from "sql.js"
import { QueryResult } from "../data/Database"
import "./ShowTable.css";

export function ShowTable(props: { rows: QueryResult, onMouseEnter?: (row: Record<string, SqlValue>) => unknown, onMouseLeave?: (row: Record<string, SqlValue>) => unknown }) {
    if (!props.rows || props.rows.length === 0) {
        return <div></div>
    }
    const header_cells = Object.keys(props.rows[0]).map((column) => {
        return <th key={column}>{column}</th>
    })
    const header = <tr>{header_cells}</tr>
    const body = props.rows.map((row) => {
        const row_cells = Object.keys(row).map((key, i) => {
            const val = row[key];
            if (key == "link" && val != null) {
                return <td key={i}><a href={val as string} target="_blank">Link</a></td>
            }
            return <td key={i}>{val}</td>
        })
        return <tr key={row[0]?.toString()} onMouseEnter={() => props.onMouseEnter ? props.onMouseEnter(row) : null} onMouseLeave={() => props.onMouseLeave ? props.onMouseLeave(row) : null}>{row_cells}</tr>
    })

    return <table className="topsong">
            <thead>
                {header}
            </thead>
            <tbody>
                {body}
            </tbody>
        </table>
}