import { ChangeEvent, useContext, useEffect, useState } from "react";
import { DbContext, DbProxy } from "../data/Database";
import { clear } from "../data/permanence";
import prettyBytes from 'pretty-bytes';
import { uploadFile } from "../fileHandling";
import { NavLink } from "react-router-dom";
import "./Welcome.css"
import { Progress, consolidateProgress } from "../util";

export function Welcome(props: { loading: boolean, setLoading: (loading: boolean) => void }) {
    const db = useContext(DbContext);
    const [progress, setProgress] = useState<Progress>({ progressed: 0, of: 0 })

    const filesChosen = async (evt: ChangeEvent<HTMLInputElement>) => {
        props.setLoading(true);
        console.log("Set loading!")
        await new Promise((resolve) => {
            setTimeout(resolve, 100);
        })

        const progressor = consolidateProgress(Array.from(evt.target.files ?? []).map((f) =>
            uploadFile(db, f)
        ))

        for await (const calcprogress of progressor) {
            setProgress({ ...calcprogress })
            await new Promise((resolve) => {
                setTimeout(resolve);
            })
        }

        evt.target.value = "";

        props.setLoading(false);
        db.save();
    }

    let centerElement = (
        <label className="uploadbutton">
            <input multiple type="file" onChange={filesChosen}></input>
            Upload
        </label>
    );

    if (props.loading && progress.of) {
        centerElement = <progress id="file" max={progress.of} value={progress.progressed} />;
    } else if (props.loading) {
        centerElement = <progress />
    }

    return <div className="welcome">
        <div className="center">
            <p><a href="https://www.spotify.com/us/account/privacy/#select-1" target="_blank">Request</a> your data from Spotify and analyze it here!</p>
            {centerElement}
        </div>
        <NavLink className='helplink' to="/help">How does this work?</NavLink>
    </div>
}